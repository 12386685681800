@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #322a26;
  position: relative;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}
#title,
#about-title {
  color: #fffffa;
  margin: 50px auto 30px;
  width: 80%;
  text-align: center;
}

#title h1,
#about-title h1 {
  font-size: 60px;
  font-weight: 400;
}
#title h2,
#about-title h2 {
  font-size: 30px;
  font-weight: 400;
}
.searchContainer form {
  width: 100%;
  height: 100%;
  margin: 50px 0;
}

i {
  position: absolute;
  color: #322a26;
  font-size: 30px;
  margin: 20px 20px;
  cursor: pointer;
  transition: 0.5s ease;
}
.searchContainer {
  background-color: #fffffa;
  color: #fffffa;
  max-width: 600px;
  width: 90%;
  max-height: 70px;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}
.searchContainer.open {
  background-color: #fffffa;
  color: #322a26;
  max-height: 600px;
  height: auto;
  transition: all 0.5s ease;
}

.searchContainer span {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px auto;
  width: 90%;
}
.searchContainer select {
  color: rgba(255, 255, 255);
  font-size: 20px;
  font-family: "Nunito", sans-serif;
}
.searchContainer span label {
  margin-bottom: 10px;
}
.searchContainer span input,
.searchContainer span select {
  max-width: 300px;
  width: 90%;
  height: 50px;
  margin: 0 auto;
  border: none;
  box-shadow: 4px 4px 4px 4px rgba(86, 130, 89, 0.25),
    -4px -4px 4px 4px rgba(66, 122, 161, 0.25);
  text-align: center;
  background-color: rgba(50, 42, 38, 0.842);
  border-radius: 10px;
}

.searchContainer span input:focus,
.searchContainer span select:focus {
  outline: none;
}

label {
  font-size: 30px;
  text-transform: uppercase;
}

input {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  color: rgb(255, 255, 255);
}
#submit {
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  background-color: rgba(50, 42, 38, 0.842);
  color: #fffffa;
  margin: 40px auto 30px;
  padding: 5px 27px;
  border: none;
  text-transform: uppercase;
  border-radius: 10px;
}
#submit:hover {
  background-color: #322a26;
}

.resultsContainer {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.result {
  transition: all 0.3s ease-in;
  transform: translateX(-10px);
  max-width: 350px;
  width: 90%;
  height: 350px;
  margin: 20px;
  padding: 24px 17px;
  color: #fffffa;
  text-align: center;
  border-radius: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.result * {
  margin-top: 0%;
}
.result div h1 {
  font-size: 30px;
  text-transform: uppercase;
}
.result div h2 {
  font-size: 20px;
}
.result div p {
  font-size: 20px;
}

#nameAndResult {
  grid-area: span 1 / span 2;
}

#birthYearAndResult {
  grid-area: span 1 / span 1;
}
#deathYearAndResult {
  grid-area: span 1 / span 1;
}
#nationalityAndResult {
  grid-area: span 1 / span 1;
}
#eraAndResult {
  grid-area: span 1 / span 1;
}

.result:nth-child(odd) {
  background-color: rgba(86, 130, 89, 0.8);
}

.result:nth-child(even) {
  background-color: rgba(66, 122, 161, 0.8);
}

div > h2 {
  font-size: 35px;
}

/* .example-enter {
  opacity: 0.01;
  transform: translateX(-20px);
}

.example-enter.example-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 2000ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 2000ms ease-in;
} */

.nav {
  width: 100%;
  /* border-bottom: 2px solid rgb(66, 121, 161); */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: rgba(66, 121, 161, 0.4) 0px 5px, rgba(66, 121, 161, 0.3) 0px 10px,
    rgba(66, 121, 161, 0.2) 0px 15px, rgba(66, 121, 161, 0.1) 0px 20px,
    rgba(66, 121, 161, 0.05) 0px 25px;
}

.nav-link {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
  padding: 0 25px;
  color: rgb(116, 173, 120);
}
.nav-link:hover {
  color: rgb(139, 201, 144);
}

#about-title a {
  text-decoration: none;
  color: rgb(139, 201, 144);
  word-wrap: break-word;
}

.about-container {
  margin: 20px auto;
  padding: 50px 30px;
  background-color: #fffffa;
  color: #322a26;
  max-width: 600px;
  width: 90%;
  height: auto;
  border-radius: 20px;
  font-size: 20px;
}
.about-container a {
  text-decoration: none;
  color: rgb(139, 201, 144);
  font-weight: 700;
}
figure {
  width: 300px;
  margin: 10px auto;
}
figcaption {
  font-size: 16px;
}
.about-container img {
  width: 300px;
}

@media (max-width: 460px) {
  .nav-link {
    font-size: 18px;
  }
}
